/*------------------------------------
  Hamburgers
------------------------------------*/

.u-hamburger {
    font-size: inherit;
    line-height: 0;
    padding: $hamburger-padding;

    &:hover {
        .u-hamburger__inner {
            &,
            &::before,
            &::after {
                background-color: $hamburger-layer-hover-color;
            }
        }
    }

    &__box {
        width: $hamburger-layer-width;
        height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
        display: inline-block;
        position: relative;
    }

    &__inner {
        display: block;
        top: $hamburger-layer-height / 2;
        margin: top, $hamburger-layer-height / -2;

        &,
        &::before,
        &::after {
            width: $hamburger-layer-width;
            height: $hamburger-layer-height;
            background-color: $hamburger-layer-color;
            position: absolute;
            transition-property: transform;
            transition-duration: $hamburger-transition-duration;
            transition-timing-function: $hamburger-transition-timing-function;
        }

        &::before,
        &::after {
            content: '';
            display: block;
        }

        &::before {
            top: $hamburger-layer-height + $hamburger-layer-spacing;
            transition-property: transform, opacity;
            transition-duration: $hamburger-transition-duration;
            transition-timing-function: $hamburger-transition-timing-function;
        }

        &::after {
            top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
        }
    }

    &[aria-expanded='true'] .u-hamburger__inner,
    & .is-active .u-hamburger__inner {
        $y-offset: $hamburger-layer-spacing + $hamburger-layer-height;
        transform: translate3d(0, $y-offset, 0) rotate(45deg);

        &::before {
            transform: rotate(-45deg)
                translate3d($hamburger-layer-width / -7, $hamburger-layer-spacing * -1, 0);
            opacity: 0;
        }

        &::after {
            transform: translate3d(0, $y-offset * -2, 0) rotate(-90deg);
        }
    }

    &--white {
        .u-hamburger__inner {
            &,
            &::before,
            &::after {
                background-color: $hamburger-layer-white-color;
            }
        }

        &:hover {
            .u-hamburger__inner {
                &,
                &::before,
                &::after {
                    background-color: $hamburger-layer-white-hover-color;
                }
            }
        }
    }
}

.js-header-fix-moment {
    .u-hamburger--white {
        .u-hamburger__inner {
            &,
            &::before,
            &::after {
                background-color: $hamburger-layer-color;
            }
        }

        &:hover {
            .u-hamburger__inner {
                &,
                &::before,
                &::after {
                    background-color: $hamburger-layer-hover-color;
                }
            }
        }
    }
}
