/*------------------------------------
  Margin Spacing
------------------------------------*/

.ml-n2 {
    margin-left: -0.5rem;
}

.ml-n3 {
    margin-left: -1rem;
}

.mr-n2 {
    margin-right: -0.5rem;
}

.mt-n1 {
    margin-top: -0.25rem;
}

.mt-n5 {
    margin-top: -2rem;
}

.mt-n6 {
    margin-top: -2.5rem;
}

.mt-n9 {
    margin-top: -4rem;
}

.mt-n23 {
    margin-top: -10rem;
}

.mb-n3 {
    margin-bottom: -1rem;
}

.mb-n9 {
    margin-bottom: -4rem;
}

/* Gutters X */
.mx-gutters-1 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;

    > .col,
    > [class*='col-'] {
        padding-right: 0.25rem;
        padding-left: 0.25rem;
    }
}

.mx-gutters-2 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;

    > .col,
    > [class*='col-'] {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
}
