@mixin contactDetails-link() {
    white-space: pre;
    overflow: visible;
    font-weight: 500;
    font-style: normal;
    font-family: $font-family-sans-serif;
    color: $gray-600;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 1.2;

    &:hover {
        color: $gray-600;
        cursor: pointer;
    }
}
