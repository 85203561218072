body {
    color: $gray-900;
}

.btn-soft-primary {
    color: #377dff;
    background: rgba(55, 125, 255, 0.1);
    border-color: transparent;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

.pointer {
    cursor: pointer;
}

.push-main-lg {
    min-height: 100vh;
    padding-top: 152px;
}

#lg-nav .nav-item a.active {
    color: rgba(255, 255, 255, 1);
}

.p-15 {
    padding: 15px 0;
}

.p-tb-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.p-rl-30 {
    padding-right: 30px;
    padding-left: 30px;
}

.h3 {
    font-size: 1.25rem;
}

.u-sidebar--account__list-item {
    border-left: 3px solid #fff;
}

.u-sidebar--account__list-item .active {
    border-left: 3px solid #377dff;
    color: #377dff;
    margin-left: -3px;
}

.u-sidebar--account__list-item a:hover {
    color: #377dff;
}

#settings-menu {
    margin-left: -16px;
}

@media (min-width: 576px) {
    .p-tb-15 {
        padding-top: 0;
        padding-bottom: 0;
    }

    .p-rl-30 {
        padding-right: 0;
        padding-left: 0;
    }
    .h3 {
        font-size: 1.75rem;
    }
}

@media (max-width: 767.98px) {
    .navbar-expand-md .u-header__nav-last-item {
        display: block;
    }
    .navbar-expand-md .u-header__nav-last-item a {
        display: block;
        margin-top: 2rem;
    }
}

@media (max-width: 991.98px) and (min-width: 768px) {
    .navbar-expand-md .u-header__navbar-brand {
        padding-top: 0;
    }
}

#header {
    height: 76px;
}

.u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link {
    color: rgba(255, 255, 255, 0.5);
}

.text-buyer {
    color: #ff7744;
}

.badge-buyer {
    color: #fff;
    background-color: #ff7744;
    text-transform: capitalize;
}

a.badge-buyer:hover,
a.badge-buyer:focus {
    color: #fff;
    background-color: #045cff;
}

a.badge-buyer:focus,
a.badge-buyer.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.5);
}

.text-seller {
    color: #ffaa22;
}

.badge-seller {
    color: #fff;
    background-color: #ffaa22;
    text-transform: capitalize;
}

.badge-other {
    color: #fff;
    background-color: #377dff;
    text-transform: capitalize;
}

a.badge-seller:hover,
a.badge-seller:focus {
    color: #fff;
    background-color: #045cff;
}

a.badge-seller:focus,
a.badge-seller.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.5);
}

.progress {
    text-transform: capitalize;
}

.nav-shadow {
    box-shadow: 0 1px 3px 0 rgba(140, 152, 164, 0.25);
}

.nav-classic .nav-link {
    color: #77838f;
    border-bottom: 3px solid transparent;
    border-radius: 0;
    padding: 0.72rem 1.5rem;
    margin-bottom: -0.125rem;
    transition: 0.3s;
}

#list-view div.card {
    border-radius: 0;
}

#list-view div.card:first-of-type {
    border-top-right-radius: 0.3125rem;
    border-top-left-radius: 0.3125rem;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

#list-view div.card:last-of-type {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
}

#xs-nav a.btn-icon {
    position: relative;
    line-height: 0;
    font-size: 0.85rem;
    width: 2.125rem;
    height: 2.125rem;
    padding: 0;
    border-radius: 6rem;
}

.card-col-0 {
    flex: 1 0;
}

.card-col-2 {
    flex: 1 0 2%;
}

.card-col-10 {
    flex: 1 0 10%;
}

.card-col-20 {
    flex: 1 0 20%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.center-icon {
    margin-bottom: 2rem;
    max-width: 260px;
}

.checkbox-outline__label {
    border: 2px solid #e7eaf3;
    cursor: pointer;
}

#address-alt .u-divider::before {
    right: 100%;
    background-image: linear-gradient(45deg, transparent 0%, #585858 100%);
    background-repeat: repeat-x;
}

#address-alt .u-divider::after {
    left: 100%;
    background-image: linear-gradient(45deg, #585858 0%, transparent 100%);
    background-repeat: repeat-x;
}

#address-alt,
#otherParty {
    display: none;
}

#documentsCard .document-item {
    border-bottom: 1px solid #e7eaf3;
}

.card-deck-flex {
    flex-wrap: wrap;
    justify-content: space-between;
}

.card-flex {
    flex: 0 1 32%;
}

.card-flex-2 {
    flex: 0 1 49%;
}

@media (min-width: 992px) {
    .card-flex {
        flex: 0 1 24%;
    }
}

#close-trx .btn-lg {
    font-size: 1.55rem;
    width: 1.8rem;
    height: 1.8rem;
}

.task-complete {
    border-left: 4px solid #00c9a7 !important;
}

.task-warning {
    border-left: 4px solid #ffc107 !important;
}

.task-danger {
    border-left: 4px solid #de4437 !important;
}

.task-success {
    border-left: 4px solid #377dff !important;
}

.message-editor {
    background-color: #fff;
    border: 1px solid #e7eaf3;
    border-bottom: 4px solid #e7eaf3;
}

.message-editor .form-control {
    background-color: #fff;
    border: none;
    border-radius: 0;
}

.message-editor .btn-icon {
    color: $primary;
    font-size: 1.5rem;
    margin: 0.5rem;
}

.message-editor .btn-sm {
    padding: 0.425rem 1.125rem;
}

.message {
    align-items: flex-start;
    background-color: #fff;
    border: 1px solid #e7eaf3;
    border-top: none;
    color: #77838f;
    display: flex;
    font-size: 0.92rem;
    font-weight: 300;
    justify-content: flex-start;
    padding: 1rem 1.2rem 1rem 1rem;
}

.message-date {
    align-items: center;
    background-color: #fff;
    border-left: 1px solid #e7eaf3;
    border-right: 1px solid #e7eaf3;
    color: #77838f;
    display: flex;
    font-size: 0.92rem;
    font-weight: 300;
    justify-content: space-around;
    padding: 0.5rem;
}

.message-attachment {
    align-items: center;
    border: 1px dashed #e7e7e7;
    border-radius: 4px;
    color: #1e2022;
    display: flex;
    font-size: 0.775rem;
    font-weight: 400;
    justify-content: flex-start;
    line-height: 1;
    margin-top: 0.5rem;
    padding: 0.5rem;
}

.message-attachment-file img {
    height: 2rem;
    margin-right: 0.875rem;
}

#documentsCard {
    background-color: #fff;
    border: 1px solid #e7eaf3;
    border-top: none;
}

.progress {
    background-color: #ececec;
}

.progress,
.progress-bar {
    border-radius: 0;
}

@media (min-width: 992px) {
    .rounded-lg-left {
        border-top-left-radius: 0.3125rem !important;
        border-bottom-left-radius: 0.3125rem !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .progress,
    .progress-bar {
        border-radius: 10rem;
    }
}

#basicsAccordion .card {
    border-radius: 0;
    border-top: none;
}

#basicsAccordion .btn-icon {
    color: #377dff;
    font-size: 0.9rem;
}

.sb-avatar__text span {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.btn-pill {
    border-radius: 6.1875rem !important;
}

.tab-content {
    display: flex;
    flex-grow: 1;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

.push-main {
    display: flex;
    flex-grow: 1;
    height: 100vh;
    padding-top: 76px;
    overflow: hidden;
}
.push-main-lg {
    display: flex;
    flex-grow: 1;
    height: 100vh;
    padding-top: 144px;
    overflow: hidden;
}

.bg-light {
    background-color: #f8f9fa !important;
    flex-grow: 1;
}

.auto-complete-container {
    width: 100%;
    display: block;
    background-color: #fff;
    padding: 20px 30px;
    margin: 0;
}

.auto-complete-item {
    list-style: none;
    color: #8c98a4;
    cursor: pointer;
}

.auto-complete-item:hover {
    color: #045cff;
}

// Fade Container
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

// Custom Editable Select **Maybe a foucs state should be considered
.react-select.pristine__control.css-yk16xz-control,
.react-select.pristine__control.css-yk16xz-control:hover,
.react-select.pristine__control.css-1pahdxg-control,
.react-select.pristine__control.css-1pahdxg-control:hover,
.react-select.pristine__control.react-select__control--is-focused.react-select__control--menu-is-open.css-1pahdxg-control,
.react-select.pristine__control.react-select__control--is-focused.react-select__control--menu-is-open.css-1pahdxg-control:hover,
.react-select.pristine__control.react-select__control--is-focused.css-1pahdxg-control,
.react-select.pristine__control.react-select__control--is-focused.css-1pahdxg-control:hover {
    border: 1px solid #d5dae2;
    border-radius: 0.3125rem;
    width: 100%;
    height: 100%;
    box-shadow: none;
    background-color: transparent;
}

.react-select.danger__control.css-yk16xz-control,
.react-select.danger__control.css-yk16xz-control:hover,
.react-select.danger__control.css-1pahdxg-control,
.react-select.danger__control.css-1pahdxg-control:hover,
.react-select.danger__control.react-select__control--is-focused.react-select__control--menu-is-open.css-1pahdxg-control,
.react-select.danger__control.react-select__control--is-focused.react-select__control--menu-is-open.css-1pahdxg-control:hover,
.react-select.danger__control.react-select__control--is-focused.css-1pahdxg-control,
.react-select.danger__control.react-select__control--is-focused.css-1pahdxg-control:hover {
    border: 1px solid #de4437;
    border-radius: 0.3125rem;
    width: 100%;
    height: 100%;
    box-shadow: none;
    background-color: transparent;
}

.react-select.success__control.css-yk16xz-control,
.react-select.success__control.css-yk16xz-control:hover,
.react-select.success__control.css-1pahdxg-control,
.react-select.success__control.css-1pahdxg-control:hover,
.react-select.success__control.react-select__control--is-focused.react-select__control--menu-is-open.css-1pahdxg-control,
.react-select.success__control.react-select__control--is-focused.react-select__control--menu-is-open.css-1pahdxg-control:hover,
.react-select.success__control.react-select__control--is-focused.css-1pahdxg-control,
.react-select.success__control.react-select__control--is-focused.css-1pahdxg-control:hover {
    border: 1px solid rgba(0, 201, 167, 0.5);
    border-radius: 0.3125rem;
    width: 100%;
    height: 100%;
    box-shadow: none;
    background-color: transparent;
}

.select-icon {
    height: 50px;
    width: 50px;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        no-repeat right 1rem center/8px 10px;
}

// Side Nav
.show-nav {
    animation: show-nav 0.5s forwards;
    -webkit-animation: show-nav 0.5s forwards;
}

.hide-nav {
    animation: hide-nav 0.5s forwards;
    -webkit-animation: hide-nav 0.5s forwards;
}

@keyframes hide-nav {
    0% {
        transform: translateX($sidebar-width-neg);
        opacity: 1;
    }
    100% {
        transform: translateX(0);
        opacity: 0;
    }
}

@-webkit-keyframes hide-nav {
    0% {
        transform: translateX($sidebar-width-neg);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(0);
        opacity: 0;
    }
}

@-moz-keyframes hide-nav {
    0% {
        transform: translateX($sidebar-width-neg);
        opacity: 1;
    }
    100% {
        transform: translateX(0);
        opacity: 0;
    }
}

@keyframes show-nav {
    0% {
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        transform: translateX($sidebar-width-neg);
        opacity: 1;
    }
}

@-webkit-keyframes show-nav {
    0% {
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        transform: translateX($sidebar-width-neg);
        opacity: 1;
    }
}

@-moz-keyframes show-nav {
    0% {
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        transform: translateX($sidebar-width-neg);
        opacity: 1;
    }
}

// Activity Input
.grow-task-form {
    transform: scaleY(1) translateY(-145px);
    transition: transform 0.4s ease-in-out;
    overflow: hidden;
}

.shrink-task-form {
    transform: scaleY(0) translateY(145px);
    transition: transform 0.4s ease-in-out;
    overflow: hidden;
}

.grow-post-form {
    transform: scaleY(1);
    transition: transform 0.25s ease-in-out;
    transform-origin: top;
    overflow: hidden;
}

.shrink-post-form {
    transform: scaleY(0);
    transition: transform 0.25s ease-in-out;
    transform-origin: top;
    overflow: hidden;
}

// @keyframes shrink-task {
//     0% {
//         transform: translateY(0px);
//     }
//     100% {
//         max-height: 0;
//         transition: max-height;
//     }
// }

// @-webkit-keyframes shrink-task {
//     0% {
//         transform: translateY(0px);
//     }
//     100% {
//         transform: translateY(-98px);
//     }
// }

// @-moz-keyframes shrink-task {
//     0% {
//         transform: translateY(0px);
//     }
//     100% {
//         transform: translateY(-98px);
//     }
// }

// @keyframes grow-task {
//     0% {
//         transform: translateY(-98px);
//     }
//     100% {
//         transform: translateY(0px);
//     }
// }

// @-webkit-keyframes grow-task {
//     0% {
//         transform: translateY(-98px);
//     }
//     100% {
//         transform: transalateY(0px);
//     }
// }
// @-moz-keyframes grow-task {
//     0% {
//         transform: translateY(-98px);
//     }
//     100% {
//         transform: translateY(0px);
//     }
// }

.avatar-edit-modal {
    div.modal-body {
        padding: 0;
        align-self: center;
    }
}

.document-upload-modal {
    div.modal-body {
        padding: 0;
        align-self: center;
        width: 80%;
    }
}

.contact-upload-modal {
    position: absolute;
    width: 70vw;
    left: 7.5vw;
    margin-top: 2.5vh;
    div.modal-body {
        padding: 0;
        align-self: center;
        width: 80%;
    }
}

.task-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.task-card {
    width: 90%;
}

.task-animation:hover {
    transition: 0.35s ease-out;
    box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.35) !important;
    transform: scale(1.01);
}

.task-animation:active {
    transition: 0.35s ease-out;
    box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25) !important;
    transform: scale(0.98);
}

// PDF Viewer

.react-pdf__Document {
    .viewer-button-panel {
        position: absolute;
        top: 87% !important;
        left: 50% !important;
        align-self: center;
        transform: translate(-50%, 0%) !important;
        background-color: rgba(255, 255, 255, 1);
        height: 2.5rem;
        border-radius: $border-radius;
        opacity: 0;
        transition: opacity ease-in-out 0.3s;
        box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
    }
}

.react-pdf__Document:hover {
    .viewer-button-panel {
        position: absolute;
        top: 87% !important;
        left: 50% !important;
        align-self: center;
        transform: translate(-50%, 0%) !important;
        background-color: rgba(255, 255, 255, 1);
        height: 2.5rem;
        border-radius: $border-radius;
        opacity: 1;
        transition: opacity ease-in-out 0.3s;
        box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
    }
}

.react-pdf__Page__canvas {
    position: absolute !important;
    top: 100% !important;
    left: 50% !important;
    transform: translate(-50.1%, 0.25%) !important;
}

.react-pdf__Page__textContent {
    position: absolute !important;
    left: 50% !important;
    transform: translate(-50%, 0%) !important;
}

.panel-button {
    border: none;
    height: inherit;
    background-color: rgba(255, 255, 255, 1);
    font-size: 10px;
}
button.btn.btn-icon.ml-auto.panel-button {
    background-color: rgba(255, 255, 255, 1);
}

button.btn.btn-icon.ml-auto.panel-button:hover:not([disabled]) {
    background-color: rgba(0, 0, 0, 0.15);
}

.panel-text {
    font-size: 14px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.doc-title-input {
    background-color: rgba(255, 255, 255, 1);
    color: $dark;
    text-align: center;
    width: '75%';
}

.team-member-email:hover {
    color: #377dff !important;
}

.add-team-member-button:hover {
    color: #fff;
    background: #77838f;
    box-shadow: 0 4px 11px rgba(119, 131, 143, 0.35);
}

.no-task {
    box-sizing: border-box;
    width: 276px;
    height: 97px;
    background-color: #ffffff;
    overflow: visible;
    border-radius: 5px;
    border: 1px dashed var(--token-5de839da-2607-48c5-91d5-dba154a6e6d4, #e7eaf3);
}

.no-task-text {
    color: $gray-700;
    text-align: center;
}

// Landing & Pitch Pages

.landing-welcome {
    height: 100%;
    width: 100%;
}

.area-bg {
    background: rgba(0, 0, 0, 0) url(../../assets/img/blue_bg.png) no-repeat scroll center
        center / cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.landing-welcome-area {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
}

.landing-layer {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
    width: 100%;
}

.landing-layer img {
    box-shadow: 0 16px 32px rgba(16, 16, 16, 0.4);
}

.sub-header {
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.23) !important;
}

.modal-header .close {
    display: none;
}

.btn:not([href]):not([type]):not(:disabled):not(.disabled) {
    cursor: pointer;
}

// Transaction Details photo gallery styles
.fslightbox-container svg {
    margin: 0;
}

.photos-quantity {
    position: absolute;
    bottom: 12px;
    right: 12px;

    width: 52px;
    height: 24px;
    padding: 3px 12px;
    font-size: 14px;
    color: $white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    pointer-events: none;

    span {
        font-size: 12px;
    }
}

// Settings tabs header
.settingsTabHeader {
    padding: 27px 0 25px 32px;
    border-bottom: 1px solid $gray-300;

    &__content {
        font-size: 18px;
        color: $jblack;
    }
}

.office-status {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    box-sizing: border-box;

    &-item {
        padding: 10px 11px;
        color: #8c98a4;
    }

    &::before {
        content: ' ';
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }

    &--active {
        &::before {
            background-color: #00c9a6;
        }
    }

    &--inactive {
        &::before {
            background-color: #de4437;
        }
    }
}

.org-info input {
    max-height: 32px;
}

.org-info {
    button {
        max-height: 36px;
    }

    &__remove-phone {
        right: -20px;
    }
}

.settings-upload {
    &__label {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        width: 140px;
        background-color: #377dff;
        border-radius: 5px;
        color: #fff;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            box-shadow: 0 4px 11px rgba(55, 125, 255, 0.35);
        }
    }

    &__logo {
        height: 42px;
        width: 147px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 5px;
    }

    input[type='file'] {
        display: none;
    }
}

.settings-actions {
    gap: 20px;
}

.office-address {
    &-list {
        border: 1px solid #eee;
        margin: 0;
        padding: 0;
        border-radius: 0 0 6px 6px;

        &__item {
            list-style: none;
            padding: 5px 16px;
            cursor: pointer;

            &:hover {
                background-color: #eee;
            }
        }
    }
}

.settings-dropdown-toggle {
    width: 93px;
    height: 32px;
    background-color: transparent !important;
    border: 1px solid #e7eaf3 !important;
    border-radius: 0px 5px 5px 0px;
    color: #000 !important;
    font-size: 14px;
    font-weight: 400;
    padding: 5px 10px 7px 8px;
    position: relative;
    text-transform: capitalize;
    align-items: start;

    &:hover,
    &:focus {
        color: #000 !important;
    }
}

.settings-dropdown-toggle::after {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.link-button {
    margin: 0;

    &:hover {
        background-color: #fff;
        color: #000;
        box-shadow: 0 3px 10px rgba(255, 255, 255, 0.4);
    }

    & input[type='file'] {
        display: none;
    }
}

.new-document {
    &--container {
        position: relative;
        height: 612px;
        width: 740px;
        box-shadow: 0px 12px 15px 0px rgba(140, 152, 164, 0.1);
        border-radius: 12px;
    }

    &--heading {
        margin: 0;
        margin-bottom: 5px;
        color: #1e2022;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
    }
}

.doc-props {
    &__label {
        font-weight: 600;
    }

    &__value {
        color: #387dff;
        font-weight: normal;
    }
}
