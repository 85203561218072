/*------------------------------------
  Badge Styles
------------------------------------*/

@each $color, $value in $theme-colors {
    /* #{$color} badge */
    .badge-outline-#{$color} {
        @if $color == 'warning' {
            @include badge-outline($value, $value, $dark);
        } @else {
            @include badge-outline($value, $value, $dark);
        }
    }
}

// Overwriting Badge Border White border color
.badge-outline-white {
    border-color: $badge-outline-white-border-color;
}
