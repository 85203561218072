/*------------------------------------
  Dropdown
------------------------------------*/

/* Disable content revealing on page load */
.dropdown-unfold {
    &[aria-labelledby] {
        opacity: 0;
    }

    &[aria-labelledby][role='tabpanel'] {
        display: block;
        opacity: 1;
    }

    &[aria-labelledby].u-unfold--css-animation,
    &[aria-labelledby].u-unfold--jquery-slide {
        display: block;
        opacity: 1;
    }

    &.u-unfold--css-animation.u-unfold--hidden {
        display: none;
        opacity: 0;
        visibility: hidden;
    }
}

.u-unfold--css-animation.u-unfold--hidden {
    display: none;
    opacity: 0;
    visibility: hidden;
}

.dropdown-unfold.show {
    display: block;
    opacity: 1;
}

/* Menu */
.dropdown-menu {
    margin-top: 0.5rem;
    font-size: $dropdown-item-font-size;
    padding-top: $dropdown-padding-y;
    padding-bottom: $dropdown-padding-y;
    box-shadow: $dropdown-box-shadow;
}

/* Nav Link */
.dropdown-nav-link {
    color: $dropdown-link-color;
    font-weight: $dropdown-link-font-weight;
    font-size: $dropdown-link-font-size;

    &:hover {
        color: $dropdown-link-hover-color;
    }
}

/* Item */
.dropdown-item {
    font-size: $dropdown-item-font-size;

    &:hover {
        color: $dropdown-item-states;
    }

    &.active {
        color: $dropdown-item-states;
    }

    &-icon {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        font-size: $dropdown-icon-font-size;
        min-width: $dropdown-icon-width;
        max-width: $dropdown-icon-width;
        margin-right: 0.5rem;
    }
}

/* Toggle */
.dropdown-toggle {
    &::after {
        display: inline-block;
        font-family: $dropdown-toggle-pseudo-font-family;
        font-size: 80%;
        font-weight: 900;
        content: $dropdown-toggle-pseudo-content;
        margin-left: 0.5rem;
    }

    &-collapse {
        &::after {
            transition: $dropdown-toggle-arrow-transition;
        }

        &[aria-expanded='true'] {
            &::after {
                transform: $dropdown-toggle-arrow-collapse-rotation;
            }
        }
    }
}

/* Dropdown Positions */
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .dropdown-menu#{$infix}-top {
            top: 0;
        }

        .dropdown-menu#{$infix}-bottom {
            top: auto;
            bottom: 0;
        }
    }
}

// Active DropDown Nav Link
.dropdown-item {
    display: block;
    width: 100%;
    clear: both;
    font-weight: 400;
    color: rgba(119, 131, 143, 1);
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    padding: 0.375rem 1.5rem;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    font-size: 1rem;
    font-family: inherit;
}

a.dropdown-link.active button.dropdown-item {
    color: #377dff;
}

.drop-caret {
    -moz-transition: all 0.15s linear;
    -webkit-transition: all 0.15s linear;
    transition: all 0.15s linear;
}

.drop-caret.down {
    -ms-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

// DropDown Menu Animation
.animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    transition-timing-function: linear;
}

@keyframes slideIn {
    0% {
        transform: translateY(5rem);
        opacity: 0;
    }
    100% {
        transform: translateY(3rem);
        opacity: 1;
    }
    0% {
        transform: translateY(5rem);
        opacity: 0;
    }
}

@-webkit-keyframes slideIn {
    0% {
        -webkit-transform: transform;
        -webkit-opacity: 0;
    }
    100% {
        -webkit-transform: translateY(3rem);
        -webkit-opacity: 1;
    }
    0% {
        -webkit-transform: translateY(5rem);
        -webkit-opacity: 0;
    }
}

@keyframes slideInStayRight {
    0% {
        transform: translate3d(-8rem, 4rem, 0);
        opacity: 0;
    }
    100% {
        transform: translate3d(-8rem, 2rem, 0);
        opacity: 1;
    }
    0% {
        transform: translate3d(-8rem, 4rem, 0);
        opacity: 0;
    }
}

@-webkit-keyframes slideInStayRight {
    0% {
        -webkit-transform: transform;
        -webkit-opacity: 0;
    }
    100% {
        -webkit-transform: translateY(2rem);
        -webkit-opacity: 1;
    }
    0% {
        -webkit-transform: translateY(4rem);
        -webkit-opacity: 0;
    }
}

.slideInStayRight {
    -webkit-animation-name: slideInStayRight;
    animation-name: slideInStayRight;
}

.slideIn {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
}

.drop.btn-sm {
    padding: 0.625rem 1.125rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem !important;
}
