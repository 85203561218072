.actionsContainer {
    position: absolute;
    bottom: 30px;
    right: 50px;

    button {
        font-size: 15px;
        font-weight: 500;
        padding: 6px 16px;
        margin: 0;
    }

    .cancelButton {
        margin-right: 8px;
        background-color: $white !important;
        border: none;
        color: $red;
    }
}
