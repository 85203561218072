/*------------------------------------
  Box Shadow
------------------------------------*/

.shadow-primary-lg {
    box-shadow: $box-shadow-primary-lg !important;
}

.shadow-soft {
    box-shadow: $box-shadow-soft !important;
}
