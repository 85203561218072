/*------------------------------------
  Nav Alignments
------------------------------------*/

// Above Large Devices
@include media-breakpoint-up(lg) {
    // Right Alignment
    .u-header__navbar-body,
    .u-header__navbar-nav {
        margin-left: auto;
    }

    // Left Alignment
    .u-header-left-aligned-nav {
        .u-header__navbar-body,
        .u-header__navbar-nav {
            margin-left: 0;
            margin-right: auto;
        }

        .u-header__navbar-nav {
            width: 100%;
        }

        .u-header__navbar-brand {
            margin-left: 0;
            margin-right: 3rem;
        }

        .u-header__nav-last-item {
            margin-left: auto;
        }
    }

    // Center Alignment
    .u-header-center-aligned-nav {
        &__col {
            flex: 0 0 16.666667%;
            max-width: 16.666667%;

            &-last-item {
                text-align: right;
            }
        }

        .u-header__navbar-body,
        .u-header__navbar-nav {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

// Below Large Devices
@include media-breakpoint-down(lg) {
    // Center Alignment
    .u-header-center-aligned-nav {
        &__col {
            &:not(.u-header-center-aligned-nav__col-last-item) {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }

            &-last-item {
                flex-grow: 1;
            }
        }
    }
}

// Above Medium Devices
@include media-breakpoint-up(md) {
    // Center Alignment
    .u-header-center-aligned-nav {
        &.u-header--abs-top-sm {
            .u-header__navbar-body,
            .u-header__navbar-nav {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}
