/*------------------------------------
  Card Gutters
------------------------------------*/

// Small Devices
@include media-breakpoint-up(sm) {
    .card-sm-gutters-1 {
        margin-right: -0.25rem;
        margin-left: -0.25rem;

        .card {
            margin-right: 0.25rem;
            margin-left: 0.25rem;
        }
    }

    .card-sm-gutters-2 {
        margin-right: -0.5rem;
        margin-left: -0.5rem;

        .card {
            margin-right: 0.5rem;
            margin-left: 0.5rem;
        }
    }

    .card-sm-gutters-3 {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem;

        .card {
            margin-right: 0.9375rem;
            margin-left: 0.9375rem;
        }
    }
}

// Above Medium Devices
@include media-breakpoint-up(md) {
    .card-md-gutters-1 {
        margin-right: -0.25rem;
        margin-left: -0.25rem;

        .card {
            margin-right: 0.25rem;
            margin-left: 0.25rem;
        }
    }

    .card-md-gutters-2 {
        margin-right: -0.5rem;
        margin-left: -0.5rem;

        .card {
            margin-right: 0.5rem;
            margin-left: 0.5rem;
        }
    }

    .card-md-gutters-3 {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem;

        .card {
            margin-right: 0.9375rem;
            margin-left: 0.9375rem;
        }
    }
}

// Large Devices
@include media-breakpoint-up(lg) {
    .card-lg-gutters-1 {
        margin-right: -0.25rem;
        margin-left: -0.25rem;

        .card {
            margin-right: 0.25rem;
            margin-left: 0.25rem;
        }
    }

    .card-lg-gutters-2 {
        margin-right: -0.5rem;
        margin-left: -0.5rem;

        .card {
            margin-right: 0.5rem;
            margin-left: 0.5rem;
        }
    }

    .card-lg-gutters-3 {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem;

        .card {
            margin-right: 0.9375rem;
            margin-left: 0.9375rem;
        }
    }
}
