$contactDetails-heading-color: #505050;

@mixin heading($font-size) {
    white-space: pre;
    overflow: visible;
    font-weight: 600;
    font-style: normal;
    color: $contactDetails-heading-color;
    font-size: $font-size;
    letter-spacing: 0px;
    line-height: 1.2;
}
