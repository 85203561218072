/*------------------------------------
  Air Button Styles
------------------------------------*/

@each $color, $value in $theme-colors {
    /* #{$color} air */
    .btn-soft-#{$color} {
        @include button-soft($value, $value, $value, $value, $value);
    }

    .btn.btn-soft-#{$color} {
        color: $value;
    }
}

@each $color, $value in $secondary-colors {
    /* #{$color} air */
    .btn-soft-#{$color} {
        @include button-soft($value, $value, $value, $value, $value);
    }

    .btn.btn-soft-#{$color} {
        color: $value;
    }
}
