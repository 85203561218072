/*------------------------------------
  Stop Colors
------------------------------------*/

.stop-color {
    &-white {
        stop-color: $white !important;
    }

    &-primary {
        stop-color: $primary !important;

        &-darker {
            stop-color: $primary-darker !important;
        }

        &-lighter {
            stop-color: $primary-lighter !important;
        }
    }

    &-warning {
        stop-color: $warning !important;
    }

    &-danger {
        stop-color: $danger !important;
    }

    &-info {
        stop-color: $info !important;
    }

    &-indigo {
        stop-color: $indigo !important;
    }
}
