/*------------------------------------
  Indicator Dots
------------------------------------*/

.u-indicator-dots {
    position: relative;

    @include media-breakpoint-up(md) {
        &::after {
            position: absolute;
            right: -2.1875rem;
            top: 50%;
            width: 2.4375rem;
            height: 0.75rem;
            background-image: $indicator-dots-bg;
            background-repeat: no-repeat;
            content: '';
            margin-top: -0.375rem;
        }
    }
}
