/*------------------------------------
  Header Submenu Background Colors
------------------------------------*/

.u-header--sub-menu-dark-bg {
    @each $breakpoint in map-keys($grid-breakpoints) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        &#{$infix} {
            @include media-breakpoint-up($breakpoint) {
                .u-header__sub-menu,
                .u-header__promo {
                    background-color: $dark;

                    &-title {
                        color: rgba($white, 0.8);
                    }
                }

                .u-header__promo-footer {
                    background-color: darken($dark, 1%);

                    &-ver-divider {
                        &::before {
                            border-color: rgba($white, 0.1);
                        }
                    }
                }

                .u-header__promo-link {
                    &::after {
                        border-color: rgba($white, 0.1);
                    }
                }

                .u-header__promo-item {
                    border-color: rgba($white, 0.1);
                }

                .u-header__sub-menu-nav-link {
                    color: $gray-600;

                    &:hover {
                        color: $primary;
                    }
                }

                .u-header__product-banner {
                    background-color: darken($dark, 1%);

                    &-text {
                        color: $gray-600;
                    }
                }

                .dropdown {
                    &-nav-link {
                        &:hover {
                            color: rgba($white, 0.8);
                        }
                    }

                    &-divider {
                        border-color: rgba($white, 0.1);
                    }
                }
            }
        }
    }
}
