@import '../base/mixins';

@mixin round-avatar($size) {
    width: $size !important;
    height: $size !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50px;
}

@mixin label($backgound-color) {
    width: max-content;
    height: 18px;
    padding: 0 5px;
    text-align: center;

    background-color: $backgound-color;
    color: $white;
    border-radius: 13px;

    font-size: 12px;
    font-weight: bold;
}

.contact-frame {
    max-height: 750px;
    width: 740px;
    position: relative;

    background-color: $white;
    border-radius: 12px;
    box-shadow: 0px 12px 15px 0px rgba(140, 152, 164, 0.1);

    &__toggle {
        &:hover {
            box-shadow: none !important;
        }

        &:focus {
            box-shadow: none !important;
        }
    }

    &__avatar {
        @include round-avatar(100px);

        &--small {
            @include round-avatar(50px);
        }
    }

    &__relative {
        margin-left: 18px;
        gap: 10px;

        &-fullname {
            @include heading(16px);
        }
    }

    &__fullname {
        @include heading(20px);
    }

    &__actions {
        &-button {
            bottom: 0;
            right: 0;
            margin: 30px;
            background-color: #00c9a7;
            border-color: #00c9a7;
            color: $white;
            cursor: pointer;

            &:hover {
                color: $white;
            }
        }
    }
}

.contact {
    &__details {
        a {
            @include contactDetails-link();
        }

        &-row {
            gap: 15px;
        }
    }
}

.notes {
    &__heading {
        @include heading(16px);
    }
}

.history {
    &__heading {
        @include heading(16px);
    }

    &__list {
        max-height: 120px;
    }
}

.label--seller {
    @include label(#ffaa22);
}

.label--buyer {
    @include label(#ff7744);
}
