/*------------------------------------
  Error State
------------------------------------*/

.u-has-error {
    .form-label + .form-control,
    .custom-select,
    .input-group,
    .form-control:first-child:last-child {
        box-shadow: $form-error-box-shadow;
    }

    .input-group-text {
        color: $form-error-addon-color;
    }

    &:focus,
    *:focus,
    .input-group-text,
    .form-control {
        border-color: $form-error-border-color;
    }
}
