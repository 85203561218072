/*------------------------------------
  Divider
------------------------------------*/

.u-divider {
    position: relative;
    display: inline-block;
    font-size: $divider-font-size;

    &::before,
    &::after {
        position: absolute;
        top: 50%;
        content: '';
    }

    &::before {
        right: 100%;
        @include gradient-x(
            $hs-start-color: transparent,
            $hs-end-color: rgba($gray-400, 1),
            $deg: 45deg
        );
    }

    &::after {
        left: 100%;
        @include gradient-x(
            $hs-start-color: rgba($gray-400, 1),
            $hs-end-color: transparent,
            $deg: 45deg
        );
    }
}
