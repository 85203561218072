/*------------------------------------
  Absolute Positions
------------------------------------*/

.u-header--abs {
    @each $breakpoint in map-keys($grid-breakpoints) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            &-top,
            &-bottom,
            &-top-2nd-screen {
                &#{$infix} {
                    position: absolute;
                }
            }

            &-top#{$infix} {
                top: 0;
                bottom: auto;
            }

            &-top-2nd-screen#{$infix} {
                &:not(.js-header-fix-moment) {
                    top: 100%;
                    bottom: auto;
                }

                &[data-header-fix-effect] {
                    transition: none;
                }
            }

            &-bottom#{$infix}:not(.js-header-fix-moment) {
                top: auto;
                bottom: 0;
            }

            &-bottom {
                &#{$infix}:not(.js-header-fix-moment) {
                    .u-header__sub-menu {
                        top: auto;
                        bottom: 100%;

                        .u-header__sub-menu {
                            top: 0;
                            bottom: auto;
                        }
                    }

                    [class*='navbar-expand'] .u-header__navbar-nav .u-header__sub-menu {
                        @include border-top-radius($header-sub-menu-top-border-radius);
                        @include border-bottom-radius(0);
                        box-shadow: $header-absolute-bottom-submenu-box-shadow;
                    }

                    .slideInUp {
                        animation-name: absoluteSlideInUp;
                    }
                }
            }
        }
    }
}

// Medium Devices
@include media-breakpoint-between(md, md) {
    .u-header--abs-bottom-md {
        &:not(.js-header-fix-moment) {
            .navbar-expand-md {
                .hs-mega-menu {
                    bottom: 52.8%;
                }
            }
        }
    }
}

// Small to Medium Devices
@include media-breakpoint-between(sm, md) {
    .u-header--abs-bottom,
    .u-header--abs-bottom-sm {
        &:not(.js-header-fix-moment) {
            .navbar-expand-md {
                .hs-mega-menu {
                    bottom: 52.8%;
                }
            }
        }
    }
}

// Long list of links, can lead to a bug, since the header can not calculate the height when.
// For this reason, max-height is given to avoid header disappearing issues on collapse.
.u-header--abs-top__inner,
.u-header--abs-bottom__inner,
.u-header--abs-top-2nd-screen__inner {
    max-height: $header-absolute-top-height;

    &.mCS_destroyed {
        position: static !important;
    }
}

[class*='u-header--abs'] {
    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        margin: 0 24px;
    }
}

/* Disable box-shadow on mobile device */
.u-header--abs {
    &-top-2nd-screen,
    &-bottom {
        &:not(.js-header-fix-moment) {
            [class*='navbar-expand'] {
                .u-header__navbar-nav {
                    .u-header__sub-menu {
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

@keyframes absoluteSlideInUp {
    from {
        transform: translate3d(0, -10px, 0);
    }
}
