.personalInfo {
    padding: 48px 50px 30px 32px;

    input {
        max-height: 32px;
    }
}

.addPhone {
    color: $blue;
    font-weight: 600;
    font-size: 11px;
    cursor: pointer;
}

.removePhoneIcon {
    right: -20px;
    top: 10px;
}

.borderColor {
    border: 1px solid $gray-300;
}

.draftEditor {
    max-width: 529px;
    max-height: 180px;
    margin-left: -10px;
    padding: 0;
}

.editorSize {
    height: 150px;

    .DraftEditor-root {
        padding-left: 15px;
    }
}

// Modal
.personalInfoModal {
    .modal-header {
        padding: 20px;

        .close {
            display: block !important;
            font-weight: lighter;
            font-size: 30px;
            color: $dark;
        }

        .modal-title {
            font-size: 16px;
            font-weight: 600;
            color: $jblack;
        }
    }

    .modal-body {
        padding: 30px 25px 35px;

        input {
            max-height: 45px;
            padding: 12px;
        }
    }

    .modal-footer {
        padding: 0 25px 40px;
        border-top: none;

        button {
            font-size: 15px;
            font-weight: 500;

            padding: 6px 16px;
            margin: 0;
        }

        button:first-child {
            margin-right: 8px;
            background-color: $white !important;
            border: none;
            color: $red;
        }
    }
}
