/*------------------------------------
  Print styles
------------------------------------*/

@media print {
    header,
    footer,
    .btn {
        display: none;
    }
}
