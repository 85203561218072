/*------------------------------------
  Background Soft Colors
------------------------------------*/

@each $color, $value in $theme-colors {
    .bg-soft-#{$color} {
        background-color: rgba($value, 0.1);
    }
}

@each $color, $value in $secondary-colors {
    .bg-soft-#{$color} {
        background-color: rgba($value, 0.1);
    }
}

.bg-soft-light {
    background-color: $gray-100;
}
