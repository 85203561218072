/*------------------------------------
  Skippy
------------------------------------*/

@if $enable-accessibility {
    .u-skippy {
        display: block;
        color: $white;
        background-color: theme-color('primary');
        padding: 1em;
        outline: 0;
        transition: all 0.2s ease-in;

        &:hover {
            color: $white;
        }

        // fix header absolute overlapping skippy content
        &:focus {
            + .u-header--abs-top {
                &:not(.u-header--moved-up):not(.u-scrolled),
                &-sm:not(.u-header--moved-up):not(.u-scrolled),
                &-md:not(.u-header--moved-up):not(.u-scrolled),
                &-lg:not(.u-header--moved-up):not(.u-scrolled),
                &-xl:not(.u-header--moved-up):not(.u-scrolled) {
                    margin-top: 3.5rem;
                }
            }

            + .u-header--sticky-top {
                &:not(.u-header--moved-up):not(.u-scrolled),
                &-sm:not(.u-header--moved-up):not(.u-scrolled),
                &-md:not(.u-header--moved-up):not(.u-scrolled),
                &-lg:not(.u-header--moved-up):not(.u-scrolled),
                &-xl:not(.u-header--moved-up):not(.u-scrolled) {
                    margin-top: 3.5rem;
                }
            }
        }

        .u-skiplink-text {
            padding: 0.5em;
            outline: 1px dotted;

            &:hover {
                color: $white;
            }
        }
    }
} @else {
    .u-skippy {
        display: none;
    }
}
