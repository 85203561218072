/*------------------------------------
  Width
------------------------------------*/

.min-width-3 {
    min-width: 1rem !important;
}

.min-width-4 {
    min-width: 1.5rem !important;
}

.min-width-15 {
    min-width: 7rem !important;
}

.min-width-21 {
    min-width: 10rem !important;
}

.max-width-3 {
    max-width: 1rem !important;
}

.max-width-4 {
    max-width: 1.5rem !important;
}

.max-width-5 {
    max-width: 2rem !important;
}

.max-width-6 {
    max-width: 2.5rem !important;
}

.max-width-8 {
    max-width: 3.5rem;
}

.max-width-9 {
    max-width: 4rem;
}

.max-width-10 {
    max-width: 4.5rem;
}

.max-width-11 {
    max-width: 5rem;
}

.max-width-15 {
    max-width: 7rem;
}

.max-width-19 {
    max-width: 9rem;
}

.max-width-23 {
    max-width: 11rem;
}

.max-width-27 {
    max-width: 13rem;
}

.max-width-35 {
    max-width: 17rem;
}

.max-width-40 {
    max-width: 19.5rem;
}

.max-width-50 {
    max-width: 24.5rem;
}

.max-width-60 {
    max-width: 29.5rem;
}

/*------------------------------------
  Height
------------------------------------*/

.height-40vh {
    height: 40vh;
}

.height-60vh {
    height: 60vh;
}

.height-100vh {
    height: 100vh;
}

.height-4 {
    height: 0.25rem;
}

.height-250 {
    height: 15.625rem;
}

.height-380 {
    height: 23.75rem;
}

.height-400 {
    height: 25rem;
}

.height-450 {
    height: 28.125rem;
}

.min-height-100vh {
    min-height: 100vh;
}

.min-height-155 {
    min-height: 9.6875rem;
}

.min-height-300 {
    min-height: 18.75rem;
}

.min-height-380 {
    min-height: 23.75rem;
}

.min-height-450 {
    min-height: 28.125rem;
}

.min-height-550 {
    min-height: 34.375rem;
}

.min-height-600 {
    min-height: 37.5rem;
}

.min-height-620 {
    min-height: 38.75rem;
}

// Small Devices
@include media-breakpoint-up(sm) {
    .height-sm-100vh {
        height: 100vh;
    }
}

// Medium Devices
@include media-breakpoint-up(md) {
    .height-md-60vh {
        height: 60vh;
    }

    .height-md-100vh {
        height: 100vh;
    }

    .min-height-md-100vh {
        min-height: 100vh;
    }
}

// Large Devices
@include media-breakpoint-up(lg) {
    .height-lg-100vh {
        height: 100vh;
    }

    .min-height-lg-auto {
        min-height: auto;
    }

    .min-height-lg-100vh {
        min-height: 100vh;
    }
}

// Extra Large Devices
@include media-breakpoint-up(xl) {
    .min-height-xl-100vh {
        min-height: 100vh;
    }
}
