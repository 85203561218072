//
// Buttons
//

// Air Buttons
@mixin button-soft(
    $hs-color,
    $hs-background,
    $hs-hover-background,
    $hs-active-background,
    $hs-box-shadow
) {
    color: ($hs-color);
    background: rgba($hs-background, 0.1);
    border-color: transparent;

    &[href],
    &[type] {
        &:hover,
        &:focus,
        &:active,
        &.active {
            color: color-yiq($hs-hover-background);
            background: ($hs-hover-background);
            box-shadow: $btn-box-shadow-value rgba($hs-background, 0.35);
        }

        &.disabled,
        &:disabled {
            color: $hs-background;
            background-color: rgba($hs-background, 0.1);
            box-shadow: none;
        }

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        .show > &.dropdown-toggle {
            color: color-yiq($hs-active-background);
            background-color: $hs-active-background;
        }
    }
}

// Icon Buttons
@mixin button-text($hs-color, $hs-hover-color) {
    color: ($hs-color);
    background: transparent;

    &[href],
    &[type] {
        &:hover,
        &:focus,
        &:active {
            color: ($hs-hover-color);
        }
    }
}
